import "tabler-react/dist/Tabler.css";

import * as React from "react";
import { Router } from "@reach/router";

import Faq from "./pages/faq/Faq";
import Home from "./pages/home/Home";
import List from "./pages/list/List";

function App() {
  return (
    <React.StrictMode>
      <Router>
          <List path="/list" />
          <Faq path="/faq" />
          <Home path="/" />
      </Router>
    </React.StrictMode>
  );
}

export default App;