import React, { Component } from "react";
import { Form, Button, Card, Header } from "tabler-react";
import CSVReader from "react-csv-reader";
import "./style.css";

class DrawForm extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.props.onChange(e.target);
  }

  render() {
    const {
      style,
      className,
      onSubmit,
      placeholder,
      value,
      drawItems,
      handleSkipAnimationChange,
      handleRemoveDrawnItemChange,
      handleUpload,
    } = this.props;

    return (
      <div style={style} className={className}>
        {/* <Header.H3>Configuration</Header.H3> */}
        <Card>
          <Card.Body>
            <Header.H3>Upload Entries</Header.H3>
            <Form.Group>
              <CSVReader
                onFileLoaded={(data, fileInfo, originalFile) =>
                  handleUpload(
                    data.map((d) => d[0]).slice(1, data.map((d) => d[0]).length - 1),
                    data
                  )
                }
              />
            </Form.Group>

            <Form.Group>
              <Form.Checkbox
                name="skipAnimation"
                label="Skip Animation"
                onChange={handleSkipAnimationChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Checkbox
                name="noRepeat"
                label="Remove Drawn Item"
                onChange={handleRemoveDrawnItemChange}
              />
            </Form.Group>
          </Card.Body>
        </Card>

        {/* <Card>
          <Card.Body>
            <div className="form">
              <Header.H3>Manual Input</Header.H3>
              <Form onSubmit={onSubmit}>
                <Form.FieldSet>
                  <Form.Group label="List of Items" isRequired>
                    <Form.Textarea
                      name="drawItems"
                      placeholder={placeholder}
                      value={value}
                      onChange={this.handleChange}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Checkbox
                      name="skipAnimation"
                      label="Skip Animation"
                      onChange={handleSkipAnimationChange}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Checkbox
                      name="noRepeat"
                      label="Remove Drawn Item"
                      onChange={handleRemoveDrawnItemChange}
                    />
                  </Form.Group>
                  <Button
                    color="primary"
                    type="submit"
                    disabled={drawItems.length <= 2}
                  >
                    Configure
                  </Button>
                </Form.FieldSet>
              </Form>
            </div>
          </Card.Body>
        </Card> */}
      </div>
    );
  }
}

export default DrawForm;
