import React from "react";
import { Card, Table } from "tabler-react";
import "./style.css";

const PreviouslyDrawnItemsBlock = (props) => {
  console.log(props.items);
  return (
    <>
      <Card>
        <Card.Body>Total Raffle Entries: {props.items.length}</Card.Body>
      </Card>

      <Card
        title={
          props.result
            ? `Total Raffle Entries: ${
                props.data.filter((item) => item[0] === props.result).length
              }`
            : "Raffle Entries"
        }
        className="past-drawn-block"
        body={
          <Table>
            <Table.Body className="past-drawn-item">
              {props.pastDrawnItems.length === 0
                ? "No previous item."
                : props.data
                    .filter((item) => item[0] === props.result)
                    .map((item, index) => (
                      <Table.Row key={index}>
                        <Table.Col>{index + 1}</Table.Col>
                        <Table.Col>{item[0]}</Table.Col>
                        <Table.Col>{item[1]}</Table.Col>
                      </Table.Row>
                    ))}
            </Table.Body>
          </Table>
        }
      />
      <Card
        title="Previously Drawn"
        className="past-drawn-block"
        body={
          <Table>
            <Table.Body className="past-drawn-item">
              {props.pastDrawnItems.length === 0
                ? "No previous item."
                : props.pastDrawnItems.map((item, index) => (
                    <Table.Row key={index}>
                      <Table.Col>{index + 1}</Table.Col>
                      <Table.Col>{item}</Table.Col>
                      <Table.Col>
                        {props.items.filter((i) => i === item).length}
                      </Table.Col>
                    </Table.Row>
                  ))}
              <Table.Row>
                <Table.Col>
                  <b>{props.pastDrawnItems.length}</b>
                </Table.Col>
                <Table.Col></Table.Col>
                <Table.Col>
                  <b>Total Draws</b>
                </Table.Col>
              </Table.Row>
            </Table.Body>
          </Table>
        }
      />
    </>
  );
};

export default PreviouslyDrawnItemsBlock;
